<template>
    <div>
     {{$route.params.id}}
     {{event}}
     <w-top-bar>Entradas</w-top-bar>
      <div class="flex justify-center items-center">
    <div class="grid grid-cols-3 gap-4 mt-4">
      <!-- Columna 1 -->
      <div class="bg-white p-6 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-2">Net Sales</h3>
        <p class="whitespace-pre-line">$542,400.00</p>
        <p class="text-gray-500 font-light">$578,431.62 gross sales</p>
      </div>

      <!-- Columna 2 -->
      <div class="bg-white p-6 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-2">Ticket Sold</h3>
        <p class="whitespace-pre-line">140/140</p>
        <p class="text-gray-500 font-light">140 paid . 0 free</p>
        
      </div>

      <!-- Columna 3 -->
      <div class="bg-white p-6 rounded-lg shadow px-4
      ">
        <h3 class="text-lg font-semibold mb-2">Page Views</h3>
        <p class="whitespace-pre-line">537</p>
        <p class="text-gray-500 font-light">47 from Eventbrite</p>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center mt-4 mx-12">
    <div class="">
      <!-- Encabezado -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-lg font-semibold">Recent Payout</h2>
        <button class="flex items-center space-x-1 text-gray-500 hover:text-gray-700">
          <span>none</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 7.293a1 1 0 0 0 0 1.414L10.586 12l-3.293 3.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 0z" />
          </svg>
        </button>
      </div>

      <!-- Columnas -->
      <div class="grid-cols-3 flex justify-center items-center gap-2 border-black border-1">
        <!-- Columna 1 -->
        <div class="bg-white p-6 px-24 ">
          <h3 class="text-lg font-semibold mb-2">$0.00</h3>
          <p class="text-green-500 mb-2">Paid</p>
        </div>

        <!-- Columna 2 -->
        <div class="bg-white p-6 px-24  border-l border-r border-gray-200">
          <h3 class="text-lg font-semibold mb-2">$542,000.00</h3>
          <p class="mb-2">Remaining</p>
        </div>

        <!-- Columna 3 -->
        <div class="bg-white p-6 px-24 ">
          <h3 class="text-lg font-semibold mb-2">$0.00</h3>
          <p class="mb-2">Adjustments</p>
        </div>
      </div>
    </div>
  </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  layout: "default",
  name: "entrada-id",
  data() {
    return {
      event: null,
      id: this.$route.params.id
    };
  },
  computed: {
  },
  async created() {
    await this.fetchEvent();
  },
  methods: {
    ...mapActions("products", ["find"]),
    async fetchEvent() {
      try {
        const response = await this.find({ query: { _id: this.id } });
        this.event = response.data;
        console.log("Datos del evento:", response.data);
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    }
  }
};
</script>
